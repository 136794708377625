/* variables */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primary-joist: #4a1ca6;
  --light-primary: #f8f9fa;
  --dark-primary: #131516;
  --bg-dark-primary: rgba(255, 255, 255, 0.07);
  --bg-dark-primary-2: rgba(255, 255, 255, 0.2);
  --secondary-grey: #f5f3ff;
  --grey-50: #fafafa;
  --grey-100: #f4f4f5;
  --grey-200: #e4e4e7;
  --grey-300: #d4d4d8;
  --grey-400: #a1a1aa;
  --grey-500: #71717a;
  --grey-600: #52525b;
  --grey-700: #3f3f46;
  --grey-800: #27272a;
  --grey-900: #18181b;

  --dark-text: #18181b;

  --green-50: #f0fdf4;
  --green-100: #dcfce7;
  --green-200: #bbf7d0;
  --green-300: #86efac;
  --green-400: #4ade80;
  --green-500: #22c55e;
  --green-600: #16a34a;
  --green-700: #15803d;
  --green-800: #166534;
  --green-900: #14532d;

  --red-50: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;

  --orange-50: #fff7ed;
  --orange-100: #ffedd5;
  --orange-200: #fed7aa;
  --orange-300: #fdba74;
  --orange-400: #fb923c;
  --orange-500: #f97316;
  --orange-600: #ea580c;
  --orange-700: #c2410c;
  --orange-800: #9a3412;
  --orange-900: #7c2d12;
}

.vjs-tip {
  visibility: hidden !important;
}

.uppy-Dashboard-AddFiles {
  height: calc(100% - 14px);
  margin: 7px;
  border: none !important;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #000000 !important;
  color: #fff !important;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  color: #000000 !important;
  background-color: transparent !important;
  border: 1px solid #000000 !important;
}

.uppy-Dashboard-browse {
  color: #000000 !important;
  cursor: pointer;
}

.uppy-DashboardContent-addMore {
  color: #fff !important;
  background-color: #000000 !important;
}

.uppy-DashboardContent-back,
.uppy-DashboardContent-save {
  color: #fff !important;
  background-color: #000000 !important;
}

.uppy-StatusBar-actionBtn {
  color: #fff !important;
  background-color: #000000 !important;
}

.highlight {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f8f9fa;
}

.display-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tab-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 9px 15px;
}

.copy-icon {
  width: 38;
  height: 21px;
}

.icon-button-container {
  border-radius: 6px;
  border: 1px solid #949494;
  padding: 6px 12px;
}

.tab-child {
  padding: 20px 20px;
}

.text-result-container {
  margin-top: 0px;
}

.open-close-icon {
  justify-content: normal;
}

.input-container {
  margin-top: 20px;
}

.advanced-search-button {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.new-proposal-input {
  /* margin: 10px 0; */
  /* margin-top: 10px !important; */
}

.full-screen {
  width: 100%;
  height: 100vh;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100vh;
}

.new-proposal-page-wrapper {
  display: flex;
}

.display-center {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.align-horizontal-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-propsal-button {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-spacing {
  padding: 10px;
}

.new-proposal-input-size {
  max-width: 500px;
}

#input-calendar input {
  padding: 12px 14px !important;
}

#input-calendar > div > div {
  /* width: -webkit-fill-available !important; */
  width: 100% !important;
}

#input-calendar .MuiOutlinedInput-notchedOutline {
  border: #000000b3 1px solid;
}

#input-calendar .AppFDS-MuiOutlinedInput-root {
  border: #c4c4c4 1px solid;
}

.create-document-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.read-more-link {
  cursor: pointer !important;
  color: #5377dd !important;
  text-decoration: none !important;
}

.search-result-image {
  width: 50%;
  padding: 5px;
}

.basic-search-button {
  margin-top: 15px;
}

#basic-search-dropdown .MuiSelect-select {
  padding: 10px !important;
}

#basic-search-dropdown .MuiFormControl-root {
  width: 100% !important;
}

.generate-container {
  padding: 15px;
}

.generate-button {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.generated-text {
  margin-top: 15px;
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3); */
  background-color: var(--grey-100);
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: var(--grey-500);
  border: 1px solid #000000;
  border-radius: 10px;
}

.search-loader-container {
  height: 100%;
}

.fullpage-loader {
  height: 100vh;
}

.loader-caption {
  margin-top: 10px;
}

.loader-flex {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000000;
  color: #000000;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin-left: 45%;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000000;
  color: #000000;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000000;
  color: #000000;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

[id^='search-result-open-pdf']:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #000000;
}

@keyframes dotFlashing {
  0% {
    background-color: #000000;
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

.customResizeHander {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: ew-resize;
  box-sizing: border-box;
  padding: 0 5px 5px 0;
}

.AppFDS-MuiPickersDay-daySelected {
  background-color: #000000 !important;
}

.image-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 0px;
  box-shadow: 0 0 10px grey;
  /* width: 40%; */
  border-radius: -2px;
  margin: 2px;
}

.inside-image {
  width: 100%;
  height: 150px;
  border-radius: 2px;
}

.libContainer {
  padding: 15px;
  padding-top: 5px;
}

#mui-tab {
  /* margin-top: -40px; */
}

#mui-tab .MuiTabs-flexContainer button:first-child {
  padding: 0px !important;
  width: auto;
  min-width: inherit;
  margin-right: 16px;
  margin-bottom: -5px;
  margin-left: 20px;
}

#mui-tab .MuiTabs-flexContainer button {
  padding: 0px !important;
  width: auto;
  min-width: inherit;
  margin-right: 16px;
  margin-bottom: -5px;
}

#mui-settings-tab .MuiTabs-flexContainer button:first-child {
  padding: 0px !important;
  width: auto;
  min-width: inherit;
  margin-right: 16px;
  margin-bottom: -5px;
  margin-left: 20px;
}

#mui-settings-tab .MuiTabs-flexContainer button {
  padding: 0px !important;
  width: auto;
  min-width: inherit;
  margin-right: 16px;
  margin-bottom: -5px;
}

#demo-customized-menu ul {
  max-width: 291px;
}

.parent-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px;
  border-color: #eeeeee;
  border-style: solid;
  border-radius: 5px;
  /* background-color: rgb(248, 248, 248); */
  padding: 2px 0;
}

.option-toolbar {
  border: 1px solid #eeeeee;
  float: right;
}

.removed-border {
  border: none;
}

.rezise-cursor {
  cursor: 'ew-resize !important';
}

.ContentEditable__root {
  height: 100%;
  overflow: auto;
  max-height: 1px;
}

.AiGenerate .ContentEditable__root {
  min-height: '70px';
  max-height: '250px';
  overflow: auto;
}

.AiGenerate .Placeholder__root {
  top: 75px;
  left: 45px;
}

.libContainer .ContentEditable__root {
  overflow: auto;
  height: 100%;
  max-height: 1px;
}

#library-editor .ContentEditable__root {
  height: 100%;
  overflow: auto;
  max-height: 1px;
  padding: 10px 6px;
}

#library-editor .Placeholder__root {
  top: 10px;
  left: 9px;
}

#searchInput .MuiInputBase-input::before {
  border-bottom: 2px solid red !important;
}

#searchInput .MuiInputBase-input::after {
  border-bottom: 2px solid green !important;
}

#react-select-9-listbox > div > div {
  border-bottom: 1px solid green;
}

div[class$='MenuList'] {
  padding-bottom: 0px !important;
  /* max-height: 269px; */
  height: 100%;
  overflow: auto;
}

div[class$='MenuList'] > div {
  border-bottom: 1px solid #d9d9d9 !important;
  padding: 0 10px !important;
  word-break: break-word;
  display: flex;
  align-items: center;
  color: #000000 !important;
}

.keyValueEdit {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.keyValueEdit:focus {
  text-overflow: initial;
}

.actions {
  padding-top: 5px !important;
}

.action-button {
  padding: 5px !important;
}

/* Styles to override the aws login page  styles */
.submitButton-customizable {
  border-color: none !important;
  background-color: #000000;
}

.submitButton-customizable:hover {
  background-color: #1f1d1d !important;
}

.submitButton-customizable:active {
  background-color: #1f1d1d !important;
}

.submitButton-customizable:focus {
  background-color: #1f1d1d !important;
}

.idpDescription-customizable {
  color: red !important;
}

/* Styles to override lexical toolbar */

.dropdown {
  position: fixed;
  /* left:45% !important;
  right: 27%;
  margin-right: 9px; */
}

#tool-bar * {
  font-family: 'PoppinsRegular';
}

#editor-drop-down {
  font-family: 'PoppinsRegular';
  font-size: 12px;
}

.overlayButtons {
  display: flex;
  opacity: 0;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  cursor: pointer;
  color: black;
  background-color: #ffffff8c;
}

.deleteButton {
  opacity: 1;
  z-index: 2;
  cursor: pointer;
  color: black;
  background-color: transparent;
}

.rpv-core__page-layer {
  overflow: inherit !important;
}

.rpv-search__highlights {
  z-index: 0;
}

.rpv-canvas canvas {
  z-index: 10;
}

.rpv-default-layout__container {
  border: none;
}

.rpv-default-layout__toolbar {
  background-color: var(--grey-100);
}

.rpv-thumbnail__container {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px 0px;
}

.rpv-thumbnail__list-container {
  background-color: var(--grey-100);
}
/* .rpv-core__inner-pages {
  overflow: scroll !important
} */

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  color: grey;
}

.highlightPlugin .plugin:nth-child(n + 2) {
  /* border-left: 1px solid white; */
}

#search-tabs .Mui-selected {
  color: var(--grey-800) !important;
}
#search-tabs .MuiTabs-indicator {
  background-color: var(--grey-800) !important;
}

#search-tabs .MuiTabs-flexContainer > :nth-child(2) {
  margin-left: 7px;
}

#search-tabs .MuiTabs-flexContainer > :nth-child(3) {
  margin-left: 7px;
  min-width: 55px;
}

#search-tabs .MuiTabs-flexContainer > :nth-child(4) {
  display: flex;
  align-items: center;
  min-width: 50px;
  margin-left: 7px;
}

#search-tabs .MuiTab-root {
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.01em;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0;
}

#search-results-input .MuiOutlinedInput-root {
  padding: 0;
  padding-left: 10px;
  padding-right: 39px;
}

.PlaygroundEditorTheme__ul {
  list-style-position: outside;
}

.PlaygroundEditorTheme__ol1 {
  list-style-position: outside;
}

ol[class^='PlaygroundEditorTheme__ol'] > li > strong {
  position: relative;
  left: 10px;
}
ol[class^='PlaygroundEditorTheme__ol'] > li > span {
  position: relative;
  left: 10px;
}
ol[class^='PlaygroundEditorTheme__ol'] > li > em {
  position: relative;
  left: 10px;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking-cursor {
  display: inline-block;
  animation: blink 1s infinite;
}

.spin {
  display: inline-block;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#multiCheckboxOption {
  width: 100% !important;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  padding: 0px;
  border-bottom: 1px solid #d9d9d9;
}

#multiCheckboxOption .MuiSvgIcon-root {
  font-size: 20px;
}

#multiCheckboxOption .MuiCheckbox-root {
  font-size: 20px;
}

.chat-variable-span {
  background-color: #dbeaff;
  padding: 2px 5px;
  border-radius: 4px;
}

/* editor-css */

/* title */

.editor-title {
  font-family: 'PoppinsMedium';
  font-size: 26px;
  border: none;
  outline: none;
  padding-left: 0px;
  width: 100%;
}

.editor-title:disabled {
  background-color: white;
  color: var(--grey-800);
  cursor: text;
}

.editor-title::placeholder {
  color: #d4d4d8;
}
.editor-title-error::placeholder {
  color: #fca5a5;
}

.contentEditableStyle {
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}

.contentEditableStyle:focus {
  outline: 1px solid #d4d4d8;
  cursor: text;
}

.contentEditableStyle:hover {
  outline: 1px solid #e4e4e7;
}

.contentEditableStyle[disabled] {
  outline: none;
  padding: 0px;
}

/* rotate animation */

.rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.editor-section {
  border: 1px solid rgb(223 223 223);
  margin-bottom: 15px;
  padding: 20px;
  padding-bottom: 24px;
  background: white;
  border-radius: 3px;
  min-height: 600px;
}

.ai-color {
  color: var(--primary-joist);
}

#storyboard-editor .ContentEditable__root {
  background-color: #f8f9fa;
  padding: 15px;
}

.search-content p {
  margin: 2px 0;
}

.glass-card {
  background: rgba(221, 221, 221, 0.51);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.4px);
  -webkit-backdrop-filter: blur(10.4px);
  border: 1px solid rgba(221, 221, 221, 0.84);
}

#notes-editor .ContentEditable__root {
  /* background-color: var(--grey-50); */
  padding: 10px 8px;
  /* box-sizing: border-box; */
  /* border: 1px solid var(--grey-200); */
  /* border-radius: 6px; */
}

#notes-editor .Placeholder__root {
  top: 10px;
  left: 9px;
  visibility: hidden;
}

#notes-editor .PlaygroundEditorTheme__listItem {
  margin-top: 10px;
  font-size: 12px;
}

#multiCheckboxButtonText {
  overflow: hidden;
}

.lazy-load-image-background {
  height: 100%;
  width: 100%;
}

.options-icon {
  opacity: 0;
  transition: all 0.1s linear;
  transform: translateY(20%);
}

.options-icon-alt {
  display: block;
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  filter: blur(0) !important;
  transition: filter 0.3s;
}


.uppy-Dashboard-inner {
  border: none;
  height: 400px !important;
}

.uppy-Dashboard-AddFiles-title {
  font-size: 14px !important;
}
